import NextRouter from 'next/router'

import { clientSideOnly } from '~/domains/platform/lib/client-side-only'

import { domain } from '../domain'

interface NextTransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
}

interface NextUrlObject {
  auth?: string | null | undefined
  hash?: string | null | undefined
  host?: string | null | undefined
  hostname?: string | null | undefined
  href?: string | null | undefined
  protocol?: string | null | undefined
  search?: string | null | undefined
  slashes?: boolean | null | undefined
  port?: string | number | null | undefined
  query?: string | null | undefined | Record<string, any>
}

export type BlackBirdOptions = NextTransitionOptions

export interface BlackBirdUrl<R = null> extends NextUrlObject {
  // eslint-disable-next-line
  pathname: R | (string & {})
  urlParams?: Record<string, any>
  scroll?: boolean
}

interface TransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
  unstable_skipClientCache?: boolean
}
export default class BlackBird<R extends string> {
  private readonly $routerEngine
  private routes

  constructor(router: any, routes: string[]) {
    this.$routerEngine = router
    this.routes = routes
  }

  private parseUrlParams(url: BlackBirdUrl<R>) {
    if (url.urlParams && url.pathname) {
      const { urlParams, pathname, ...restUrl } = url
      return {
        ...restUrl,
        pathname: pathname.replace(/\${(.*?)}/g, (_, g) => (urlParams ? urlParams[g] : ''))
      }
    }

    return url
  }

  public travelTo(url: R | BlackBirdUrl<R>, options?: TransitionOptions) {
    if (typeof url === 'string') {
      return this.$routerEngine.push(url)
    }

    const parsedUrl = this.parseUrlParams(url)
    return this.$routerEngine.push(parsedUrl, undefined, { ...options })
  }

  public replace(url: R | BlackBirdUrl<R>, as?: R | BlackBirdUrl<R>, options?: BlackBirdOptions) {
    if (typeof url === 'string') {
      return this.$routerEngine.replace(url, as, options)
    }

    const parsedUrl = this.parseUrlParams(url)
    return this.$routerEngine.replace(parsedUrl, as, options)
  }

  public leave({ shouldRedirect }: { shouldRedirect?: boolean } = {}) {
    if (shouldRedirect) return this.$routerEngine.push('/sair?shouldRedirect')
    return this.$routerEngine.push('/sair')
  }

  public select({ redirectUrl = '/home' }: { redirectUrl?: string } = {}) {
    this.$routerEngine.push({
      pathname: '/trocar-de-conta',
      query: {
        redirectUrl: encodeURIComponent(String(redirectUrl))
      }
    })
  }

  public redirect(url: string) {
    const parsedUrl = new URL(url, domain())

    if (this.routes.includes(parsedUrl.pathname))
      this.$routerEngine.push({
        pathname: parsedUrl.pathname,
        query: Object.fromEntries(parsedUrl.searchParams.entries())
      })
    else this.$routerEngine.push('/sair')
  }

  public getQuery<T = any>(): Partial<T> {
    return clientSideOnly(() => this.$routerEngine.query, {})
  }

  public goHome() {
    return this.$routerEngine.push('/home')
  }

  public getRoutes() {
    return this.routes
  }

  public getPathname() {
    return window.location.pathname
  }

  public reload() {
    window.location.reload()
  }

  public back() {
    window.history.back()
  }
}

export function blackBirdFactory<R extends string>(routes: string[]) {
  if (Array.isArray(routes) && routes.length === 0) {
    throw new Error(`Provides a filled array of routes such as: [ '/login', '/update-password']`)
  }

  return new BlackBird<R>(NextRouter, routes)
}

export type BlackbirdType = InstanceType<typeof BlackBird>
