import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale/pt-BR'
export function formatDateWithHours(date: string) {
  const parseDate = parseISO(date)
  return format(parseDate, 'dd/MM/yyyy HH:mm')
}
export function formatDateTextDateWithHours(date: string) {
  const parseDate = parseISO(date)
  return format(parseDate, "d MMM 'às' HH:mm", { locale: ptBR })
}
