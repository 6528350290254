interface IconProps {
  size?: number
  fill?: string
  color?: string
  marginTop?: number
}

export const LockBadgeIllustration = ({ size = 24, fill = '#287DF3', ...rest }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill={fill}
      />
      <mask id="mask0" maskUnits="userSpaceOnUse" x="4" y="4" width="16" height="16">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.00002 9.03241C9.00839 7.29728 10.5441 5.99361 12.3068 6.00002C14.0696 6.00061 15.6 7.31113 15.6 9.04605V10.2C15.6 10.2238 15.5986 10.2472 15.5959 10.2703C16.0436 10.3386 16.4525 10.4826 16.7849 10.8151C17.1347 11.1648 17.2759 11.5991 17.3397 12.0741C17.4001 12.5228 17.4 13.0868 17.4 13.7588V14.4412C17.4 15.1132 17.4001 15.6772 17.3397 16.1259C17.2759 16.6009 17.1347 17.0352 16.7849 17.3849C16.4352 17.7347 16.0009 17.8759 15.5259 17.9397C15.0773 18 14.5132 18 13.8412 18H10.7588C10.0869 18 9.52277 18 9.07416 17.9397C8.59915 17.8759 8.16482 17.7347 7.81509 17.3849C7.46536 17.0352 7.32415 16.6009 7.26028 16.1259C7.19997 15.6772 7.19999 15.1132 7.20001 14.4412V13.7588C7.19999 13.0868 7.19997 12.5228 7.26028 12.0741C7.32415 11.5991 7.46536 11.1648 7.81509 10.8151C8.14755 10.4826 8.55647 10.3386 9.00409 10.2703C9.0014 10.2472 9.00002 10.2238 9.00002 10.2L9.00002 9.03241ZM10.7588 10.2L13.8412 10.2C14.0367 10.2 14.223 10.2 14.4 10.2015L14.4 10.2V9.04605C14.4 8.08004 13.518 7.20002 12.3056 7.20002H12.3033C11.0914 7.19521 10.2055 8.07051 10.2 9.03686V10.2L10.2 10.2015C10.377 10.2 10.5634 10.2 10.7588 10.2ZM12.9 13.4717C12.9 13.1404 12.6314 12.8717 12.3 12.8717C11.9686 12.8717 11.7 13.1404 11.7 13.4717V14.6717C11.7 15.0031 11.9686 15.2717 12.3 15.2717C12.6314 15.2717 12.9 15.0031 12.9 14.6717V13.4717Z"
          fill="#ffffff"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="4.79999" y="4.7998" width="14.4" height="14.4" fill="white" />
      </g>
    </svg>
  )
}
