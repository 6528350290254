import { IconButton } from '@dlpco/ginga-stone'
import { useToast } from '@stone-payments/jade'

import { Flex } from '~/domains/platform/design-system'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { type AnswerChallenge } from '~/domains/platform/infra/http/http-with-challenge'
import { ChallengeForm } from '~/ui/business-components'
import { Modal } from '~/ui/components/modals/modal/modal'

import { registerName } from '../services/register-name'

interface RegisterNameChallengeProps {
  name: string
  accountId: string
  onClose(): void
  challenge?: typeof ChallengeForm
}

export const RegisterNameChallenge = ({
  name,
  accountId,
  onClose,
  challenge: Challenge = ChallengeForm
}: RegisterNameChallengeProps) => {
  const { addToast } = useToast()

  const handleRequest = async (payload?: AnswerChallenge) => {
    return registerName({ accountId, name })(payload)
  }

  const handleSuccess = () => {
    addToast({
      text: 'Você cadastrou um nome pra essa conta.'
    })

    onClose()
  }

  const handleError = () => {
    addToast({
      text: 'Não foi possível cadastrar um nome.'
    })

    onClose()
  }

  return (
    <>
      <Modal.Header>
        <Flex justifyContent="end" gap="0.5rem" color="blue" pt="1rem" px="1.5rem" pb="0.5rem">
          <IconButton icon="close-outline" color="neutral" variant="contentOnly" size="large" onClick={() => {}} />
        </Flex>
      </Modal.Header>
      <Divider />
      <Modal.Content>
        <Flex minHeight="30ch" minWidth="45ch" p="0.5rem 1rem">
          <Challenge onRequest={handleRequest} onSuccess={handleSuccess} onError={handleError} />
        </Flex>
      </Modal.Content>
    </>
  )
}
