export const Endpoints = {
  invoices: {
    getAll: (id: string) => `/stores/${id}/invoices`
  },
  stores: {
    getByDocument: (document: string) => `/v1/stores/by/document/${document}`,
    getById: (id: string) => `/v1/stores/${id}`,
    update: (id: string) => `/v1/stores/${id}`
  },
  invoiceConfiguration: {
    getByStoreId: (id: string) => `/v1/stores/${id}/invoice-config`,
    update: (id: string) => `/v1/stores/${id}/invoice-config`
  },
  storeById: (id: string) => `/v1/stores/${id}`,
  invoiceConfigurationByStoreId: (id: string) => `/v1/stores/${id}/invoice-config`,
  updateInvoiceConfiguration: (id: string) => `/v1/stores/${id}/invoice-config`,
  products: {
    all: (storeId: string) => `/stores/${storeId}/products`,
    id: (storeId: string, productId: string) => `/stores/${storeId}/products/${productId}`
  },
  variations: (id: string) => `/stores/${id}/variation-types`,
  variationOptions: (storeId: string, id: string) => `/stores/${storeId}/variation-types/${id}/options`,
  categories: (id: string) => `/stores/${id}/categories`,
  subCategories: (id: string, categoryId: string) => `/stores/${id}/categories/${categoryId}/subcategories`
} as const
