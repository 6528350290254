import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'
import { Heimdall } from '~/domains/platform/core/heimdall'
import { queryClient } from '~/ui/hooks/utils'

import { CreditBucket } from '../bucket'
export const CHECK_CREDIT_ACCESS_KEY = 'credit::access::check'

type CheckCreditAccessParams = {
  organizationId: string
  document: string
}

export type SessionDataAccess = {
  organizationId: string
  hasAccess: boolean
}

type BffCheckCreditAccess = {
  content: {
    checkCreditAccess: boolean
  }
  traceKey: string
  isValid: boolean
}

export const fetchCreditAccessData = async (document: string): Promise<BffCheckCreditAccess> => {
  const hasRevolvingCredit = Heimdall.pass(['credit_revolving_credit_web', 'credit_beta_web'])
  const { data } = await httpClientCredit<BffCheckCreditAccess>({
    url:
      '/experience-bff-service/api/web/v1/home/check-credit-access' +
      (hasRevolvingCredit ? '?IncludeProductType=revolving&IncludeProductType=loan' : ''),
    params: { document }
  })
  return data
}

export const checkAccessInStorage = (organizationId: string): boolean | null => {
  const creditAccessSession = CreditBucket.get(CHECK_CREDIT_ACCESS_KEY)
  const hasNoDataInSession = typeof creditAccessSession !== 'object' && creditAccessSession !== null

  if (hasNoDataInSession) return null
  const { hasAccess: sessionHasAccess, organizationId: sessionOrganizationId } = creditAccessSession

  const hasEqualOrganizationId = sessionOrganizationId === organizationId

  if (!hasEqualOrganizationId) return null
  const isStoredDataValid = hasEqualOrganizationId && Boolean(sessionHasAccess)

  return isStoredDataValid
}

export const updateCreditAccessSession = (organizationId: string, hasAccess: boolean): void => {
  CreditBucket.set(CHECK_CREDIT_ACCESS_KEY, {
    organizationId,
    hasAccess
  })
}

export const checkCreditAccess = async ({ organizationId, document }: CheckCreditAccessParams): Promise<boolean> => {
  const hasAccessInStorage = checkAccessInStorage(organizationId)
  if (hasAccessInStorage !== null) return hasAccessInStorage

  try {
    const { content } = await queryClient.fetchQuery(
      ['useHomeCheckCreditAccess', document],
      () => fetchCreditAccessData(document),
      {
        staleTime: 5 * (60 * 1000)
      }
    )

    const hasAccess = content?.checkCreditAccess

    updateCreditAccessSession(organizationId, hasAccess)
    return Boolean(hasAccess)
  } catch (_error) {
    return false
  }
}
