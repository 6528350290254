import { useEffect, useState } from 'react'
import { type GetServerSidePropsContext } from 'next'

type ID = string | undefined

const Id = '6327fb26-ef26-4b27-a46e-11cb918c808e'

export const useStoreId = (): ID => {
  const [id, setId] = useState<ID>(undefined)
  useEffect(() => {
    setId(Id)
  }, [])
  return id
}

// eslint-disable-next-line unused-imports/no-unused-vars
export const getServerSideStoreId = async (_: GetServerSidePropsContext) => Id
