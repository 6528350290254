import dayjs from 'dayjs'
//@ts-ignore
export const simpleFormat = (dateFormat: string, date?: string | Date) => dayjs.utc(date).format(dateFormat)
export const isToday = (date: string | Date) => dayjs(date).startOf('d').isSame(dayjs().startOf('d'))
export const formatDateWithoutTimezone = (dateFormat: string, date: string | Date) =>
  //@ts-ignore
  dayjs.utc(`${date}+03:00`).format(dateFormat)

export const forceUTCinRange = (from = '', to = '', format?: string) => {
  if (from === to) {
    //@ts-ignore
    const startDate = dayjs(dayjs(from, format).utc(true).format()).toISOString()
    //@ts-ignore
    const endDate = dayjs(dayjs(to, format).utc(true).format()).add(23, 'h').add(59, 'm').add(59, 's').toISOString()

    return [startDate, endDate]
  }

  const startDate = dayjs(from, format).toISOString()

  const endDate = dayjs(to, format).toISOString()

  return [startDate, endDate]
}

/**
 * @deprecated use timeMachine instead
 */

export const date = {
  formatDateWithoutTimezone,
  simpleFormat,
  forceUTCinRange,
  isToday
}
