import { useCopyToClipboard } from 'react-use'
import { IconButton, useToast } from '@stone-payments/jade'
export const ButtonCopyWithIcon = ({ value }: { value: string }) => {
  const { addToast } = useToast()
  const [, copyToClipboard] = useCopyToClipboard()

  const handleCopy = async () => {
    try {
      copyToClipboard(value)
      addToast({ text: 'Copiado para a área de transferência' })
    } catch (error) {
      console.error('Falha ao copiar o texto: ', error)
    }
  }
  return <IconButton icon="action-copy" onClick={handleCopy} />
}
