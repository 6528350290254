import { type Dispatch, type PropsWithChildren, type SetStateAction, createContext, useContext, useState } from 'react'

export type ErrorProperties = { [key: string]: string | ErrorProperties } | null

const ErrorsContext = createContext<[state: ErrorProperties, setState: Dispatch<SetStateAction<ErrorProperties>>]>([
  null,
  () => {}
])

export const ErrorsProvider = ({ children }: PropsWithChildren<{}>) => {
  const controller = useState<ErrorProperties>(null)
  return <ErrorsContext.Provider value={controller}>{children}</ErrorsContext.Provider>
}

export const useErrors = () => useContext(ErrorsContext)
