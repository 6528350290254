// eslint-disable-next-line @nx/enforce-module-boundaries
import { Endpoints } from '../endpoints'
import { httpClient } from '../http-client'
import { handleErrorResponse } from '../utils'

export namespace Stores {
  export type Item = {
    id: string
    name: string
    document: string
    stateRegistration: string
    cnae: string
  }

  export type Request = {
    stateRegistration: string
    cnae: string
  }

  export const getByDocument = async (document: string): Promise<Item> => {
    try {
      const response = await httpClient.get<Item>(Endpoints.stores.getByDocument(document))
      return response.data
    } catch (error: unknown) {
      return handleErrorResponse(error)
    }
  }

  export const get = async (id: string): Promise<Item> => {
    try {
      const response = await httpClient.get<Item>(Endpoints.stores.getById(id))
      return response.data
    } catch (error: unknown) {
      return handleErrorResponse(error)
    }
  }

  export const update = async (id: string, payload: Request): Promise<Item> => {
    try {
      const response = await httpClient.patch<Item>(Endpoints.stores.update(id), payload)
      return response.data
    } catch (error: unknown) {
      return handleErrorResponse(error)
    }
  }
}
