export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      const result = reader.result
      if (result === null) {
        reject(new Error('File reading resulted in null'))
      } else if (typeof result === 'string') {
        const base64String = result.split(',')[1]
        if (base64String) resolve(base64String)
        else reject(new Error('Failed to extract Base64 string from result'))
      } else reject(new Error('Unexpected result type'))
    }

    reader.onerror = () => {
      reject(new Error('Error reading file'))
    }

    reader.readAsDataURL(file)
  })
}
