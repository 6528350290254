import { useEffect } from 'react'
import { type AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Script from 'next/script'
import NextNprogress from 'nextjs-progressbar'
import { GlobalStyle as GingaGlobalStyle } from '@dlpco/ginga-stone'
import { RenderRemoteComponents } from '@stone-payments/experiment/components'
import { ToastHub } from '@stone-payments/jade'
import { QueryClientProvider } from '@tanstack/react-query'
import dayjs from 'dayjs'
import Calendar from 'dayjs/plugin/calendar'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import Timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { Button, Link } from 'platform/lib/experiment'
import { ThemeProvider } from 'styled-components'
import { SwhConfigContextProvider } from 'swh/shared/components'

import { HomeTeamPendingKYCModal } from '~/domains/home/components/home-team-pending-kyc-modal'
import { authenticator } from '~/domains/platform/core/authenticator/authenticator'
import { FeedbackNotificationProvider } from '~/domains/platform/layout/feedback-notification'
import { ToastProvider } from '~/domains/platform/layout/toast'
import { analiticaInstance } from '~/domains/platform/lib/analitica/analitica.config'
import { analytics } from '~/domains/platform/lib/analytics'
import { Bucket } from '~/domains/platform/lib/bucket'
import { clientSideOnly } from '~/domains/platform/lib/client-side-only'
import { APP_ENV, APP_VERSION, DEPLOY_TYPE, DEV_TOOLS, HOTJAR_ID, RECAPTCHA_SITE_KEY } from '~/lib/constants'
import { isomorphicCookies } from '~/lib/helpers/utils/cookies'
import { appEnv } from '~/lib/helpers/utils/environment'
import { initializeAnalytics } from '~/lib/helpers/utils/ports/analytics'
import { FullScreensProvider } from '~/ui/contexts/fullscreens'
import { queryClient } from '~/ui/hooks/utils/service/use-query-request'
import { GlobalStyle, theme } from '~/ui/theme'

import 'dayjs/locale/pt-br'

// eslint-disable-next-line @nx/enforce-module-boundaries
import { enableExpHttpResponseMock } from '../devtools/plugins/index'
// eslint-disable-next-line @nx/enforce-module-boundaries
import packageInfo from '../package.json'

import '@stone-payments/jade-design-tokens/stone/fonts.css'
import '@stone-payments/jade-design-tokens/stone/color.css'
import '@stone-payments/jade-design-tokens/stone/typography.css'
import '@stone-payments/jade-design-tokens/stone/space.css'
import '@stone-payments/jade/css/index.css'
import '~/ui/theme/globals.min.css'

dayjs.extend(LocalizedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(Calendar)
dayjs.locale('pt-br')
dayjs.extend(utc)
dayjs.extend(Timezone)
dayjs.extend(isBetween)
dayjs.extend(relativeTime)

RenderRemoteComponents.registerJadeComponents()
RenderRemoteComponents.registerComponent('Link', Link)
RenderRemoteComponents.registerComponent('Button', Button)

let DevToolsPanel: any = null
if (DEV_TOOLS() === 'enabled') {
  // eslint-disable-next-line @nx/enforce-module-boundaries
  DevToolsPanel = dynamic<Record<any, never>>(() => import('../devtools').then(mod => mod.DevToolsPanel))
}

export default function App({ Component, pageProps }: AppProps) {
  const { token } = isomorphicCookies.getAll()
  const subjectId = token ? authenticator.parseToken({ token })['stone_subject_id'] : undefined

  useEffect(() => {
    analytics.update({ userId: subjectId })
    initializeAnalytics({ userId: subjectId, appVersion: packageInfo.version })
    logVersion()
    analiticaInstance.updateMetadata({ user: { id: subjectId } })
    if (process.env.NODE_ENV === 'development' && Bucket.local.get('exp::mock::enabled')) enableExpHttpResponseMock()
  }, [subjectId])

  return (
    <>
      <Head>
        {appEnv.isSandbox() ? <meta name="robots" content="noindex" /> : null}
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Acesse sua Conta da Stone.
          Confira suas vendas e recebimentos, crie links de pagamentos e realize transferências e Pix, tudo em um só lugar, na sua Conta Stone."
        />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#FFF" />
        <meta name="aplication-name" content="Stone" />
        <meta name="hotjar-id" content={String(HOTJAR_ID())} />
        <link rel="apple-touch-icon" href="/touchicon.png" />
        <link rel="icon" href="/favicon.png" type="image/png" />

        <title>Stone</title>
      </Head>
      <div className="jade">
        <GlobalStyle />
        <GingaGlobalStyle />
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <NextNprogress color={theme.colors.lightGray} startPosition={0.3} stopDelayMs={200} height={4} />
            <ToastHub>
              <ToastProvider>
                <FeedbackNotificationProvider>
                  <FullScreensProvider>
                    {clientSideOnly(() => (
                      <HomeTeamPendingKYCModal />
                    ))}

                    <SwhConfigContextProvider {...pageProps}>
                      <Component {...pageProps} />
                    </SwhConfigContextProvider>
                    <Script strategy="beforeInteractive" src={previewURL('/scripts/hotjar.js')} />
                    {/**
                     * Script available on https://assets.flex.twilio.com/releases/flex-webchat-ui/2.9.3/twilio-flex-webchat.min.js
                     */}
                    <Script strategy="beforeInteractive" src={previewURL('scripts/twilio-flex-webchat.min.js')} />
                    <Script
                      strategy="beforeInteractive"
                      src={previewURL('/scripts/core-sdk/FaceTecSDK.js/FaceTecSDK.js')}
                    />

                    <Script
                      strategy="beforeInteractive"
                      src={`https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY()}`}
                    />
                    <Script
                      dangerouslySetInnerHTML={{
                        __html: `
                      grecaptcha.enterprise.ready(function() {
                        globalThis.window.GRecaptcha = grecaptcha.enterprise
                      });
                      `
                      }}
                    />
                    {(APP_ENV() === 'sdb' || APP_ENV() === 'hml') && APP_VERSION() ? (
                      <a
                        href={`https://github.com/dlpco/dashboard/pull/${APP_VERSION()}`}
                        style={{
                          backgroundColor: 'gray',
                          borderRadius: '1rem',
                          bottom: '1rem',
                          color: 'white',
                          fontSize: '1rem',
                          fontWeight: 'bold',
                          padding: '0.5em 0.75em',
                          position: 'fixed',
                          left: '2rem',
                          opacity: 0.8,
                          textDecoration: 'none',
                          zIndex: 9999
                        }}
                      >
                        {DEPLOY_TYPE() === 'preview' ? `Preview do PR #${APP_VERSION()}` : `Versão ${APP_VERSION()}`}
                      </a>
                    ) : null}

                    {DevToolsPanel ? clientSideOnly(() => <DevToolsPanel />) : null}
                  </FullScreensProvider>
                </FeedbackNotificationProvider>
              </ToastProvider>
            </ToastHub>
          </QueryClientProvider>
        </ThemeProvider>
      </div>
    </>
  )
}

function previewURL(path: string) {
  return DEPLOY_TYPE() === 'preview' ? `/${APP_VERSION()}/${path}` : path
}

App.getInitialProps = () => {
  return {}
}

function logVersion() {
  console.info(`Build ${APP_VERSION()}`)
}
