export const isErrorResponse = (error: any): boolean => {
  return (error?.isAxiosError && Array.isArray(error.response?.data?.errors)) || false
}

export const handleErrorResponse = (error: any) => {
  if (isErrorResponse(error)) {
    const errors = error.response.data.errors?.map(
      (error: { message: string; location?: string }) => new Error(error.message, { cause: error.location })
    )
    throw new AggregateError(errors || new Error('An unknown error occurred'))
  } else {
    throw error
  }
}
