import { useEffect } from 'react'
import Image from 'next/image'
import { Stack } from '@dlpco/fluid-layout'

import { Box, Flex, Text } from '~/domains/platform/design-system'
import { OpenFinanceRedirectIllustration } from '~/domains/platform/design-system/illustrations/open-finance-redirect.illustration'
import { useOpenFinanceContext } from '~/ui/hooks/open-finance'

import OPFBrand from '../../../../../../public/open-finance/marca-open-finance.png'

export function ConsentRedirect() {
  const { data } = useOpenFinanceContext()

  const { softwareLogoUri, brandName, redirectUri } = data?.redirect ?? {}

  useEffect(() => {
    setTimeout(() => {
      // This is ok because it's a redirect to an OPF institution app
      window.location.href = redirectUri
    }, 2000)
  }, [])

  return (
    <Box p="0 1rem 1rem 1rem" maxWidth="48rem">
      <Stack space="0">
        <Flex flex="1" justifyContent="center">
          <Box p="0" mb="4rem">
            <Image
              alt="Logotipo Open Banking"
              src={OPFBrand}
              style={{ maxWidth: '13rem', width: '100%' }}
              layout="fill"
            />
          </Box>
        </Flex>

        <Flex flexDirection="column" alignContent="center" alignItems="center">
          {/**
          @disclaimer - The image is external, so we don't need to use the Image component
         */}
          <img width="100%" style={{ maxWidth: '13rem' }} alt={`Logotipo ${brandName}`} src={softwareLogoUri} />

          <Text p="1rem" fontWeight="bold" fontSize="large">
            Instituição iniciadora
          </Text>

          <OpenFinanceRedirectIllustration />

          <Text pt="1rem" fontWeight="bold" fontSize="large">
            Pronto!
          </Text>

          <Text fontSize="small" color="mediumGray" textAlign="center">
            Em alguns segundos te redirecionaremos para o ambiente da instituição onde o pagamento foi iniciado.
          </Text>
        </Flex>
      </Stack>
    </Box>
  )
}
