import { type FileRejection, useDropzone } from 'react-dropzone'
import { useToast } from '@stone-payments/jade'

import { DefaultViewer } from './dnd-upload-default-viewer'
import { FileInformation } from './dnd-upload-file-information'
import { type DndUploadProps } from './types'

export const DndUpload = ({
  idle,
  allowDelete = true,
  allowUpdate = false,
  onDeleteFile,
  onDrop,
  maxSize,
  multiple,
  accept,
  messages,
  icon,
  files,
  name,
  loading
}: DndUploadProps) => {
  const { addToast } = useToast()

  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections.forEach(() => {
      addToast({
        text: messages?.error || `Arquivo incompatível, envie no formato ${accept}`,
        action: {
          label: 'Fechar',
          onClick: () => {}
        }
      })
    })
  }
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept,
    maxSize,
    multiple: !!multiple,
    onDropRejected: onDropRejected
  })

  const onUpdateFile = () => {
    if (multiple) throw new Error('File update only works for single files')

    open()
  }

  const showViewer = files ? files.length > 0 : false
  return (
    <div>
      <input {...getInputProps()} name={name} id={name} />
      <div className={showViewer ? 'block' : 'hidden'}>
        <DefaultViewer
          allowDelete={allowDelete}
          allowUpdate={allowUpdate}
          onDeleteFile={onDeleteFile}
          onUpdateFile={onUpdateFile}
          files={files}
          icon={icon}
          message={messages?.default}
          loading={loading}
        />
      </div>
      <div className={!showViewer ? 'block' : 'hidden'}>
        <FileInformation accept={accept} maxSize={maxSize} />
        <div
          className="flex flex-col items-center justify-center border-2 rounded-jade-small p-jade-300 border-jade-border-medium border-dashed mt-jade-100"
          {...getRootProps()}
        >
          {isDragActive && <p>Solte os arquivos selecionados</p>}
          {!isDragActive && idle}
        </div>
      </div>
    </div>
  )
}
