interface IconProps {
  size?: number
  fill?: string
  color?: string
  marginTop?: number
}

export function WarningNotificationIllustration({ size = 80, ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      {...props}
    >
      <rect width={size} height={size} rx="40" fill="#F38428" fillOpacity="0.1" />
      <mask id="mask0" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="18" y="18" width="44" height="44">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 21.6666C31.3198 21.6666 25.1974 27.9715 25.1974 34.1335C25.1974 35.5487 25.0024 36.5135 24.7487 37.238C24.4928 37.9688 24.1509 38.5362 23.7271 39.1899C23.6841 39.2561 23.6395 39.3244 23.5937 39.3946C23.2054 39.9886 22.7247 40.724 22.3582 41.6075C21.9294 42.6415 21.6667 43.8339 21.6667 45.3577C21.6667 45.4151 21.6694 45.4725 21.6748 45.5296C21.7397 46.2191 21.873 46.8815 22.0879 47.5109C22.5742 48.9354 23.628 49.8291 24.7365 50.3368C25.8168 50.8316 27.0172 51 28.0743 51H32.6667C32.6667 55.05 35.9499 58.3333 40 58.3333C44.0501 58.3333 47.3334 55.05 47.3334 51H50.7457C52.1684 51 53.8428 50.7292 55.261 50.0287C56.6938 49.3209 58.1178 48.0217 58.3072 45.9396C58.3245 45.7502 58.3334 45.5563 58.3334 45.3577C58.3334 43.8339 58.0707 42.6415 57.6418 41.6075C57.2753 40.724 56.7947 39.9886 56.4064 39.3946C56.3605 39.3244 56.3159 39.2561 56.2729 39.1899C55.8491 38.5362 55.5072 37.9688 55.2513 37.238C54.9976 36.5135 54.8027 35.5487 54.8027 34.1335C54.8027 27.9715 48.6803 21.6666 40 21.6666ZM43.6667 51H36.3334C36.3334 53.025 37.975 54.6666 40 54.6666C42.0251 54.6666 43.6667 53.025 43.6667 51ZM28.8641 34.1335C28.8641 30.3473 32.9753 25.3333 40 25.3333C47.0248 25.3333 51.136 30.3473 51.136 34.1335C51.136 35.9021 51.3823 37.2836 51.7907 38.4498C52.1968 39.6096 52.7376 40.4771 53.1964 41.1847L53.3251 41.3828L53.3251 41.3828C53.7331 42.01 54.026 42.4604 54.2549 43.0122C54.488 43.5744 54.6667 44.2864 54.6667 45.3577C54.6667 45.4466 54.6627 45.5296 54.6556 45.6073C54.6231 45.9651 54.4041 46.3623 53.6371 46.7412C52.8554 47.1274 51.7729 47.3333 50.7457 47.3333H28.0743C27.3638 47.3333 26.724 47.2141 26.2633 47.0031C25.8308 46.805 25.6427 46.5747 25.558 46.3263C25.4548 46.024 25.3774 45.6753 25.3338 45.2699C25.3432 44.2473 25.5184 43.5589 25.7451 43.0122C25.974 42.4604 26.267 42.01 26.675 41.3828L26.675 41.3827L26.8036 41.1847C27.2625 40.4771 27.8033 39.6096 28.2094 38.4498C28.6177 37.2836 28.8641 35.9021 28.8641 34.1335Z"
          fill="#303742"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="18" y="18" width="44" height="44" fill="#D95E00" />
      </g>
      <circle cx="52.0002" cy="24.9679" r="8" fill="#F38428" />
    </svg>
  )
}
