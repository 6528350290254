export const BANKING_ROUTES = [
  '/extrato',
  '/extrato-v2',
  '/extrato/agendado',
  '/extrato/agendamentos',
  '/pagar',
  '/boletos',
  '/pagar/boleto',
  '/pagar/boleto/inserir-codigo',
  '/pagar/boleto/confirmar-dados',
  '/pagar/boleto/finalizar',
  '/pagar/boleto/sucesso',
  // criar boleto
  '/criar/boleto/valor',
  '/criar/boleto/selecionar-tipo-de-boleto',
  '/criar/boleto/adicionar-descricao',
  '/criar/boleto/selecionar-desconto',
  '/criar/boleto/desconto',
  '/criar/boleto/dados-do-pagador',
  '/criar/boleto/confirmar-dados',
  '/criar/boleto/cadastrar-endereco',
  '/criar/boleto/data-limite',
  '/criar/boleto/data-limite-para-pagamento',
  '/criar/boleto/buscar-cep',
  '/criar/boleto/personalizar-o-boleto',
  '/criar/boleto/taxas',
  '/criar/boleto/feedback',
  '/criar/boleto/novo-feedback',
  '/criar/boleto/metodo',
  // detalhes do boleto
  '/boleto/detalhes-do-boleto',
  '/boletos/detalhes-do-boleto/${id}',
  '/boletos/detalhes-do-boleto/recorrencia/${id}',
  '/boletos/detalhes-do-boleto/parcelado/${id}',
  // gerenciar boletos
  '/boletos/gerenciar/recorrencia',
  '/boletos/gerenciar/parcelado',
  // DDA
  '/dda',
  '/meu-dda/dda/pin',
  '/meu-dda/dda/erro',
  '/meu-dda/dda/ativado-com-sucesso',
  '/meu-dda/dda/desativado-com-sucesso',
  '/meu-dda/dda/erro-ao-desativar',
  '/meu-dda/dda/pin-desativar',
  // pagamento sem código de barras
  '/pagar/tributos',
  '/pagar/tributos/${type}',
  '/pagar/tributos/${type}/preencher-dados',
  '/pagar/tributos/${type}/revisar-dados',
  '/pagar/tributos/${type}/finalizar',
  '/pagar/tributos/${type}/sucesso',
  '/pagar/tributos/${type}/ocr',
  '/pagar/tributos/${type}/selecionar-metodo',
  // pix
  '/pix',
  '/pix/minhas-chaves',
  '/pix/minhas-chaves/pedidos-de-chaves',
  '/pix/minhas-chaves/resumo',
  '/pix/pagar/inserir-qr-code',
  '/pix/pagar/inserir-senha',
  '/pix/pagar/confirmar-dados',
  '/pix/pagar/inserir-valor-qr-code',
  '/pix/pagar/responder-recebedor',
  '/pix/pagar/calendario',
  '/pix/devolucao',
  '/pix/devolucao/confirmar',
  '/pix/devolucao/inserir-senha',
  '/pix/devolucao/inserir-valor',
  // pix qr code
  '/gerar-qr-code/inserir-valor-do-qr-code',
  '/gerar-qr-code/tipo-de-vencimento',
  '/gerar-qr-code/data-de-vencimento',
  '/gerar-qr-code/adicionar-desconto',
  '/gerar-qr-code/informar-desconto',
  '/gerar-qr-code/dados-do-pagador',
  '/gerar-qr-code/confirmar-dados',
  '/gerar-qr-code/qr-code-gerado',
  // pix em lote
  '/pix-em-lote/criacao-manual/criar-nome',
  '/pix-em-lote/criacao-manual/adicionar-dados',
  '/pix-em-lote/criacao-manual/escolher-contato',
  '/pix-em-lote/criacao-manual/visualizar-dados-bancarios',
  '/pix-em-lote/criacao-manual/transferir-valor',
  '/pix-em-lote/criacao-manual/visualizar-detalhes-do-lote',
  '/pix-em-lote/criacao-manual/visualizar-detalhes-da-conta',
  '/pix-em-lote/criacao-manual/confirmar-pagamento',
  '/pix-em-lote/criacao-manual/pagar-pix-em-lote',
  '/pix-em-lote/criacao-manual/escolher-dado-do-contato',
  '/pix-em-lote/criacao-manual/adicionar-chave-pix',
  '/pix-em-lote/criacao-manual/adicionar-dados-bancarios',
  '/pix-em-lote/criacao-manual/informar-valor',
  '/pix-em-lote/criacao-manual/descricao',
  '/pix-em-lote/criacao-manual/detalhes-do-lote',
  '/pix-em-lote/enviando-arquivo/criar-lote',
  '/pix-em-lote/enviando-arquivo/erro-na-planilha',
  '/pix-em-lote/enviando-arquivo/editar-planilha',
  '/pix-em-lote/enviando-arquivo/adicionar-conta',
  '/pix-em-lote/enviando-arquivo/visualizar-conta',
  '/pix-em-lote/enviando-arquivo/confirmar-pagamento',
  '/pix-em-lote/enviando-arquivo/pagar-pix-em-lote',
  '/pix-em-lote/historico/lotes',
  '/pix-em-lote/historico/detalhes-do-lote',
  '/pix-em-lote/rascunhos/lotes',
  // contatos
  '/contatos/meus-contatos',
  '/contatos/adicionar-contato',
  '/contatos/salvar-contato',
  '/contatos/detalhes',
  '/contatos/editar-contato',
  '/contatos/contato-confiavel',
  '/contatos/adicionar-conta',
  '/contatos/salvar-conta',
  // contatos-v2
  '/contatos-v2',
  '/contatos-v2/${id}',
  '/contatos-v2/${id}/editar-dados',
  '/contatos-v2/${id}/editar-grupos',
  '/contatos-v2/${id}/editar-grupos',
  '/contatos-v2/${id}/adicionar-conta/${step}',
  '/contatos-v2/onboarding',
  '/contatos-v2/criar-contato/${step}',
  //transfer
  '/transferir/dados-recebedor',
  '/transferir/selecione-contato',
  '/transferir/dados-da-transferência',
  '/transferir/confirmar-transferência',
  //transactional limits
  '/limites-transacionais',
  '/limites-transacionais/detalhes',
  '/limites-transacionais/alterar-limite/inserir-valor',
  '/limites-transacionais/alterar-limite/inserir-senha',
  '/limites-transacionais/periodo-diurno/alterar-periodo-diurno',
  '/limites-transacionais/periodo-diurno/inserir-senha',
  '/limites-transacionais/detalhes?operation=outbound_pix_payment',
  //abertura de conta
  '/criar-conta',

  // open finance
  '/open-finance',
  // open finance pagamento
  '/open-finance/pagamento',
  '/open-finance/iniciar-pagamento',
  '/open-finance/pagamento/confirmar-pagamento',
  '/open-finance/pagamento/cancelar-pagamento',
  '/open-finance/pagamento/inserir-senha',
  '/open-finance/pagamento/redirecionamento',
  '/open-finance/pagamento/erro-pagamento',
  '/open-finance/meus-pagamentos',
  '/open-finance/meus-pagamentos/inserir-senha',
  '/open-finance/meus-pagamentos/confirmar-cancelamento',
  '/open-finance/iniciacao-pagamento',
  '/open-finance/iniciacao-pagamento/valor-transacao',
  '/open-finance/iniciacao-pagamento/escolha-instituicao',
  '/open-finance/iniciacao-pagamento/confirmar-transacao',
  '/open-finance/iniciacao-pagamento/redirecionamento',
  '/open-finance/iniciacao-pagamento/feedback',
  '/open-finance/iniciacao-pagamento/sucesso',
  '/open-finance/iniciacao-pagamento/erro',
  // open finance novo consentimento
  '/open-finance/novo-consentimento',
  '/open-finance/novo-consentimento/suas-informacoes',
  '/open-finance/novo-consentimento/trazer-dados',
  '/open-finance/novo-consentimento/confirmacao',
  '/open-finance/novo-consentimento/compartilhamento-completo',
  '/open-finance/novo-consentimento/solicitacao-consentimento',
  '/open-finance/novo-consentimento/redirecionamento',
  '/open-finance/novo-consentimento/compartilhamento-incompleto',

  // open finance compartilhar dados
  '/open-finance/compartilhar-dados',
  '/open-finance/compartilhar-dados/selecionar-conta',
  '/open-finance/compartilhar-dados/confirmacao',
  '/open-finance/compartilhar-dados/redirecionamento',
  '/open-finance/meus-compartilhamentos',
  '/open-finance/meus-compartilhamentos/detalhe/enviado',
  '/open-finance/meus-compartilhamentos/detalhe/recebido',
  '/open-finance/meus-compartilhamentos/consentimento-cancelado-com-sucesso',
  '/open-finance/meus-compartilhamentos/recebido/editar/confirmacao',
  '/open-finance/meus-compartilhamentos/recebido/editar/sucesso',
  '/open-finance/meus-compartilhamentos/recebido/editar/erro',
  '/open-finance/meus-compartilhamentos/recebido/editar/redirecionamento',
  '/open-finance/meus-compartilhamentos/recebido/renovar/confirmacao',
  '/open-finance/meus-compartilhamentos/recebido/renovar/sucesso',
  '/open-finance/meus-compartilhamentos/recebido/renovar/erro',
  '/open-finance/meus-compartilhamentos/recebido/renovar/redirecionamento',

  //link de pagamento
  '/link-de-pagamento',
  '/link-de-pagamento/v2',
  '/link-de-pagamento/v2/criar',
  '/link-de-pagamento/v2/detalhes/${paymentLinkId}',
  //agenda
  '/agenda-financeira',
  //saque
  '/saque',
  '/saque/token',
  '/saque/token/criar',
  '/saque/token/criar/confirmar',
  '/saque/token/criar/dados-do-saque',

  //débito automático
  '/pagar/debito-automatico/onboarding',
  '/pagar/debito-automatico/cadastrar-conta/home',
  '/pagar/debito-automatico/cadastrar-conta/selecionar-empresa',
  '/pagar/debito-automatico/cadastrar-conta/inserir-codigo',
  '/pagar/debito-automatico/cadastrar-conta/confirmar-dados',
  '/pagar/debito-automatico/cadastrar-conta/confirmar-cadastro',
  '/pagar/debito-automatico/cadastrar-conta/feedback',
  '/pagar/debito-automatico/historico/detalhes',
  '/pagar/debito-automatico/cadastrar-conta/selecionar-servico',

  //minhas finanças
  '/minhas-financas',
  '/minhas-financas/categorias',
  '/minhas-financas/categorias/extrato/${categoryPathName}',
  '/minhas-financas/categorias/extrato/${categoryPathName}/alterar-categoria/${transactionId}',

  //folha de pagamento
  '/folha-de-pagamento',
  '/folha-de-pagamento/rascunhos',

  //recorrencias
  '/recorrencias',
  '/cancelar-recorrencia/agendamentos',
  '/cancelar-recorrencia/inserir-senha',
  '/cancelar-recorrencia/feedback'
] as const
