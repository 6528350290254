import { Badge, Icon, ListItem, Text, Tooltip } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'

import { useGetHome } from '~/domains/home-v2/shared/hooks/get-bff'
import { type Analytics, type MenuItem } from '~/domains/home-v2/types'
import { Box, Flex } from '~/domains/platform/design-system'
import { type Entity, type Subject } from '~/domains/platform/infra/deus-ex-machina/types'
import BlackBird, { type routes } from '~/domains/platform/lib/blackbird'
import { publish } from '~/domains/platform/lib/pub-sub'
import { Choose, For, If } from '~/domains/platform/lib/utilities-components'

import analitica from '../analitica'

export function MainMenu({
  entity,
  subject
}: {
  entity: Entity<'acquirer' | 'banking'>
  subject: Subject<'stone_account_resources'>
}) {
  const { menu } = useGetHome({
    document: subject?.document,
    accountId: entity?.paymentAccount?.id,
    globalId: entity.id,
    affiliationKey: entity?.stoneCodes[0]?.affiliationKey
  })

  function handleAction({
    uri,
    analytics,
    label
  }: {
    uri: (typeof routes)[number]
    analytics: Analytics
    label?: string
  }) {
    analitica.events.mainMenu.click(analytics)
    if (uri === null && label === 'Equipe') {
      publish('show:team:modal')

      return
    }

    BlackBird.travelTo(uri)
  }

  return (
    <Box data-testid={menu?.id}>
      <If
        condition={menu?.data?.length > 0}
        render={() => (
          <For
            of={menu?.data as Array<MenuItem>}
            render={item => {
              const { analytics, icon, label, locked, navigation, order, show, tag, tooltip } = item

              const active = () => [navigation].includes(BlackBird.getPathname() as (typeof routes)[number])

              return (
                <If key={order} condition={Boolean(show)}>
                  <ListItem
                    variant={active() ? 'active' : 'neutral'}
                    compact
                    content={
                      <Flex alignItems="center" gap="0.5rem">
                        <Text
                          role="menu-item-label"
                          color={active() ? 'brand' : 'high'}
                          variant="text-medium"
                          weight="medium"
                          style={{ opacity: locked ? 0.5 : 1 }}
                        >
                          {label}
                        </Text>
                        <If condition={Boolean(tooltip)}>
                          <Tooltip
                            placement="right"
                            text={tooltip}
                            trigger={<Icon size="small" use="circle-alert" />}
                          />
                        </If>
                      </Flex>
                    }
                    leading={
                      <Icon
                        role="menu-item-icon"
                        size="medium"
                        color={active() ? 'brand' : 'medium'}
                        use={icon}
                        style={{ opacity: locked ? 0.5 : 1 }}
                      />
                    }
                    onClick={
                      !locked
                        ? () => {
                            handleAction({
                              uri: navigation as (typeof routes)[number],
                              analytics: analytics as Analytics,
                              label
                            })
                          }
                        : undefined
                    }
                    trailing={
                      <Choose>
                        <Choose.When condition={Boolean(locked)}>
                          <Flex
                            bg={jadeTheme.theme.color.background.info}
                            p={jadeTheme.global.space[50]}
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="1rem"
                          >
                            <Icon role="menu-lock-icon" use="lock-close" size="small" color="on-color" />
                          </Flex>
                        </Choose.When>
                        <Choose.When condition={Boolean(tag)}>
                          <Badge label={tag as string} variant="text" voice="info" />
                        </Choose.When>
                      </Choose>
                    }
                  />
                </If>
              )
            }}
          />
        )}
      />
    </Box>
  )
}
