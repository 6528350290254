import type React from 'react'
import NextLink from 'next/link'
import { Button as JadeButton, GroupHeader, Link as JadeLink, Text } from '@stone-payments/jade'

type LinkProps = {
  description?: string
  label: string
  href: string
}

type ButtonProps = {
  label: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  loading?: boolean
  type?: 'submit' | 'reset' | 'button'
  form?: string
}

type SectionProps = {
  title: string
  description?: string
  trailing?: React.ReactNode
  link?: LinkProps
  button?: ButtonProps
}

const Link = ({ label, href, description }: LinkProps) => {
  if (!label || !href) return null

  return (
    <div className="flex flex-col">
      {description && (
        <Text variant="text-small" color="medium">
          {description}
        </Text>
      )}
      <JadeLink as={NextLink} href={href} external size="medium" variant="brand">
        {label}
      </JadeLink>
    </div>
  )
}

const Button = ({ label, onClick, loading, disabled = false, type = 'submit', form }: ButtonProps) => {
  if (!label) return null

  return (
    <div className="ml-auto">
      <JadeButton
        variant="primary-solid"
        size="small"
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        type={type}
        form={form}
      >
        {label}
      </JadeButton>
    </div>
  )
}

const Footer = ({ link, button }: { link?: LinkProps; button?: ButtonProps }) => {
  if (!link && !button) return null

  return (
    <div className="flex flex-row align-middle justify-between p-2 bg-jade-slate-11 bg-neutral-50">
      {link && <Link {...link} />}
      {button && <Button {...button} />}
    </div>
  )
}

export const Section = ({
  title,
  trailing,
  description,
  link,
  button,
  children
}: React.PropsWithChildren<SectionProps>) => (
  <div className="w-full flex flex-col bg-white rounded-xl overflow-hidden">
    <GroupHeader className="flex flex-row align-middle justify-between p-2" title={title} trailing={trailing} />

    <div className="w-full px-jade-250 pb-jade-300">
      <Text color="high">{description}</Text>
      {children}
    </div>

    <Footer link={link} button={button} />
  </div>
)
