import { type ReactNode } from 'react'
import { useToggle } from 'react-use'
import Image from 'next/image'
import {
  type IconId,
  Avatar,
  DropdownMenu,
  DropdownMenuDivider,
  DropdownMenuItem,
  IconButton,
  IconShape,
  Text
} from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'
import styled from 'styled-components'

import { useGetHome } from '~/domains/home-v2/shared/hooks/get-bff'
import { type DropDownItem } from '~/domains/home-v2/types'
import { Box, Flex } from '~/domains/platform/design-system'
import { type Entity, type Subject } from '~/domains/platform/infra/deus-ex-machina/types'
import BlackBird from '~/domains/platform/lib/blackbird'
import { If } from '~/domains/platform/lib/utilities-components'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

import analitica from './analitica'

const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  width: 100%;
  background-color: ${jadeTheme.theme.color.background.surface};
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px -1px rgba(0, 0, 0, 0.04);
  border-top: 0.188rem solid ${jadeTheme.theme.color.background.brand};
  padding: ${jadeTheme.global.space[100]};
  position: fixed;
  z-index: 1000;
  & a {
    display: block;
    & img {
      max-height: 20px;
    }
  }
`

const CustomHoverBox = styled(Box)<{ isViewSmall?: boolean }>`
  border-radius: ${jadeTheme.global.space[50]};
  cursor: pointer;
  &:hover {
    background-color: ${props => (!props.isViewSmall ? jadeTheme.theme.color.background['surface-hover'] : 'none')};
  }
`

interface HeaderProps {
  subject: Subject<'stone_account_resources'>
  entity: Entity<'acquirer' | 'banking'>
  children: ReactNode
  open: boolean
  toggleOpen: () => void
}

export function Header(props: HeaderProps) {
  const { header, isLoading } = useGetHome({
    document: props.subject?.document,
    accountId: props.entity?.paymentAccount?.id,
    globalId: props.entity.id,
    affiliationKey: props.entity?.stoneCodes[0]?.affiliationKey
  })

  const [openDropDown, toggleDropDown] = useToggle(false)

  const { isViewMedium, isViewSmall } = useViewSize()

  function handleDropDownClick({
    analytics,
    path,
    externalLink = false
  }: {
    analytics?: DropDownItem['analytics']
    path?: DropDownItem['navigation']
    externalLink?: boolean
  }) {
    analytics?.forEach(item => analitica.events.dropDownMenu.click(item))

    if (externalLink) {
      window.open(path, '_blank')
      return
    }

    if (path) BlackBird.travelTo(path)
  }

  return (
    <Box data-testid={header?.id}>
      <HeaderContainer>
        <Flex>
          <If condition={isViewMedium || isViewSmall}>
            <Box p={`${jadeTheme.global.space[50]} ${jadeTheme.global.space[100]}`}>
              <IconButton
                onClick={props.toggleOpen}
                icon={props.open ? 'close' : 'menu-hamburger'}
                size="medium"
                role="menu-hamburger"
              />
            </Box>
          </If>

          <If condition={!isViewSmall}>
            <Flex alignItems="center" p={jadeTheme.global.space[50]} style={{ cursor: 'pointer' }}>
              <Image
                alt="logotipo-stone"
                src="/logo-stone.svg"
                height={20}
                width={95}
                onClick={() => {
                  BlackBird.goHome()
                  analitica.events.header.click()
                }}
              />
            </Flex>
          </If>
        </Flex>
        {!isLoading && (
          <Box tabIndex={0}>
            <DropdownMenu
              open={openDropDown}
              toggleOpen={() => {
                header?.analytics?.forEach(item => analitica.events.dropDownMenu.click(item))
                toggleDropDown()
              }}
              trigger={
                <CustomHoverBox isViewSmall={isViewSmall}>
                  <Flex
                    alignItems="center"
                    gap={jadeTheme.global.space[150]}
                    p={`${jadeTheme.global.space[50]} ${jadeTheme.global.space[100]}`}
                  >
                    <If condition={!isViewSmall}>
                      <Flex flexDirection="column" alignItems="end">
                        <Text variant="text-small" weight="semibold">
                          {header?.name}
                        </Text>
                        <Text variant="text-small" color="medium">
                          {header?.email}
                        </Text>
                      </Flex>
                    </If>
                    {openDropDown ? (
                      <IconShape size="small" use="chevron-down" variant="brand" />
                    ) : (
                      <Avatar
                        label={header?.name
                          .split(' ')
                          .map(name => name[0])
                          .join('')}
                        size="small"
                      />
                    )}
                  </Flex>
                </CustomHoverBox>
              }
              allowedPlacements={['right']}
            >
              {header?.items.map((item, index) => (
                <DropdownMenuItem
                  key={index}
                  icon={item?.icon}
                  label={item?.label}
                  onClick={() => handleDropDownClick({ analytics: item.analytics, path: item.navigation })}
                />
              ))}

              <DropdownMenuDivider />

              {header?.footer.map((item, index) => (
                <DropdownMenuItem
                  key={index}
                  icon={item?.icon}
                  label={item?.label}
                  onClick={() =>
                    handleDropDownClick({ analytics: item.analytics, path: item.navigation, externalLink: true })
                  }
                />
              ))}

              <DropdownMenuDivider />

              <DropdownMenuItem
                icon={header?.exit.icon as IconId}
                label={header?.exit.label as string}
                variant="danger"
                onClick={() =>
                  handleDropDownClick({
                    analytics: header?.exit.analytics,
                    path: header?.exit.navigation,
                    externalLink: false
                  })
                }
              />
            </DropdownMenu>
          </Box>
        )}
      </HeaderContainer>

      <Flex flexDirection="column" minHeight="100vh" pt="4rem">
        {props.children}
      </Flex>
    </Box>
  )
}
