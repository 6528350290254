import { Text } from '@stone-payments/jade'

import { type Mime } from './types'

const MAX_SIZE_10MB = 1048576

type Accept = Mime | Mime[]
type MaxSize = number

type FileInformationProps = {
  accept?: Accept
  maxSize?: MaxSize
}

const MaxSizeInformation = ({ maxSize }: { maxSize: MaxSize }) => {
  if (!maxSize) return null

  const sizeMaxFile = maxSize > 0 ? maxSize / MAX_SIZE_10MB : 0

  return (
    <Text as="span" variant="text-small" color="medium" weight="medium">
      Tamanho do arquivo: até {sizeMaxFile}MB
    </Text>
  )
}

const AcceptInformation = ({ types }: { types: Accept }) => {
  if (!types) return null

  const acceptsTypes = Array.isArray(types) ? types.join(', ') : types

  return (
    <Text as="span" variant="text-small" color="medium" weight="medium">
      Formatos aceitos: {acceptsTypes}
    </Text>
  )
}

export const FileInformation = ({ accept, maxSize }: FileInformationProps) => {
  if (!accept || !maxSize) return null

  return (
    <div className="flex justify-between mt-jade-100">
      {maxSize && <MaxSizeInformation maxSize={maxSize} />}
      <AcceptInformation types={accept} />
    </div>
  )
}
