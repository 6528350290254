import { type PropsWithChildren, type ReactNode } from 'react'
import { Divider, PageTitle } from '@stone-payments/jade'

export const PageContainer = (
  props: PropsWithChildren<{
    title: string
    description: ReactNode
  }>
) => (
  <main className="flex flex-col p-jade-400 gap-jade-250 relative">
    <PageTitle title={props.title} description={props.description as string} />
    {props.children}
  </main>
)

// eslint-disable-next-line @typescript-eslint/ban-types
export const PageCard = (props: PropsWithChildren<{}>) => (
  <section className="border border-solid border-jade-border-low flex flex-col gap-jade-200 p-jade-200 rounded-jade-small">
    {props.children}
  </section>
)

export const PageWithActionContainer = (props: PropsWithChildren<{ title: string; description: ReactNode }>) => (
  <main className="flex flex-col p-jade-400 gap-jade-250 relative">
    <header className="flex flex-col justify-between">
      <PageTitle title={props.title} />
      {props.description}
    </header>
    <Divider />
    {props.children}
  </main>
)
