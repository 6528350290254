/* eslint-disable testing-library/no-await-sync-query */
import { useEffect } from 'react'
import { type NextPage } from 'next'
import { Stores } from 'swh/shared/http'

import { withPageConfig } from '~/domains/platform/infra/page-enhancers/with-page-config'
import { SWH_MODE } from '~/lib/constants'

import { useSwhConfigContext } from './swh-config-context'
import { withSwhStoreConfig } from './with-swh-store-config'

export function performanceWrapper<P extends NextPage<any, any>>(Page: P) {
  return function PerformanceWrapper(props: any) {
    const [config, setConfig] = useSwhConfigContext()

    useEffect(() => {
      const effect = async () => {
        const store = await Stores.getByDocument('12345678910')
        setConfig({ store: store })
      }
      effect()
    }, [])

    if (config === null) return null

    return (
      <div className="h-full flex flex-col">
        <div
          className="flex flex-col bg-white w-full h-20"
          style={{ boxShadow: '0px 4px 4px rgba(13, 17, 27, 0.08)', borderTop: '4px solid #00a868', zIndex: 2 }}
        >
          Header
        </div>
        <div className="flex flex-row" style={{ minHeight: '100vh' }}>
          <div
            style={{ width: '16rem', minWidth: 'min(100vw, 16rem)' }}
            className="bg-white flex items-center justify-center"
          >
            sideBar
          </div>
          <div className="w-full">
            <Page {...props} />
          </div>
        </div>
      </div>
    )
  }
}

export function withSwhConfig<P extends NextPage<any, any>>(Page: P, pageConfig?: object) {
  return function WithFeatureFlagDev(props: any) {
    const isProd = process.env.APP_ENV === 'prod'
    const isSWHPerformanceMode = SWH_MODE() === 'true'
    if (isProd) {
      return null
    } else if (isSWHPerformanceMode) {
      const PerformanceWrapper = performanceWrapper(Page)
      return <PerformanceWrapper {...props} />
    } else {
      const Component = withPageConfig(Page, pageConfig)
      const WithCoinfigComponent = withSwhStoreConfig(Component)
      return <WithCoinfigComponent {...props} />
    }
  }
}
