// eslint-disable-next-line @nx/enforce-module-boundaries
import { Endpoints } from '../endpoints'
import { httpClient } from '../http-client'
import { handleErrorResponse } from '../utils/errors'

const ITEMS_PER_PAGE = 25

export namespace Invoices {
  export type Customer = {
    document: string
    documentType: 'cpf' | 'cnpj'
  }

  export enum Status {
    Authorized = 'authorized',
    Authorizing = 'authorizing',
    Rejected = 'rejected',
    Canceled = 'canceled',
    Canceling = 'canceling',
    Default = 'undefined'
  }

  export type Item = {
    id: string
    saleId: number
    customer: Customer
    number: number
    series: number
    status: Status
    totalAmount: number
    danfeUrl: string
    createdAt: string
    updatedAt: string
  }

  export type Response = {
    items: Item[]
    nextPageCursor?: string
  }

  export const getAll = async (id: string, pageCursor: string | null = null): Promise<Response> => {
    const endpoint = `${Endpoints.invoices.getAll(id)}?pageSize=${ITEMS_PER_PAGE}&pageCursor=${pageCursor}`

    try {
      const response = await httpClient.get<Response>(endpoint)
      return response.data
    } catch (error: unknown) {
      return handleErrorResponse(error)
    }
  }
}
