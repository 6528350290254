import { type InvoiceConfiguration, type Stores } from 'swh/shared/http'

const checkConfigurations = (
  store?: Stores.Item,
  configuration?: InvoiceConfiguration.Item,
  checkAll: boolean = true
): boolean => {
  if (!store || !configuration) return false

  const { certificate, csc, numberingControl } = configuration || {}
  const { stateRegistration } = store || {}

  const configs = [certificate?.isConfigured, csc?.isConfigured, numberingControl?.isConfigured, !!stateRegistration]

  return checkAll ? configs.every(config => config === true) : configs.some(config => config === true)
}

export const isInvoiceConfigured = (store?: Stores.Item, configuration?: InvoiceConfiguration.Item): boolean => {
  return checkConfigurations(store, configuration, true)
}

export const isSomeConfigured = (store?: Stores.Item, configuration?: InvoiceConfiguration.Item): boolean => {
  return checkConfigurations(store, configuration, false)
}
