import { useEffect, useMemo, useState } from 'react'
import { useToggle } from 'react-use'
import dynamic from 'next/dynamic'
import { Button, Icon, Text } from '@dlpco/ginga-stone'

import { checkCreditAccess } from '~/domains/credit/ports/services/check-credit-access'
import { useCheckCreditAccess } from '~/domains/credit/shared/hooks/use-check-credit-access'
import { Box, Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { theme } from '~/ui/theme'

import { CreditBucket } from '../bucket'
import { type MainMenuRevolvingCreditContent } from '../entities'
import analitica from '../helpers/analitica'
import getRevolvingCreditOfferType from '../helpers/get-revolving-credit-offer-type'
import { useRevolvingCredit } from '../hooks/use-revolving-credit'

const SESSION_KEY = 'credit::revolving::balance::view::event::submitted'

const OnboardingModal = dynamic<any>(
  () =>
    import('~/domains/credit/revolving-credit/concession/components/concession-onboarding-modal').then(
      mod => mod.ConcessionOnboardingModal
    ) as any
)

export type MetadataReturn = {
  title: string
  subtitle: string
  id: string
  type: 'followUp' | 'offer'
  amount?: number
  negotiationStatus?: string
  freeLimit?: number
  contractedAmount?: number
}

const findRevolvingCreditMetadata = (content: MainMenuRevolvingCreditContent): MetadataReturn | null => {
  if (!content) return null
  const revolvingCreditItems = Object.values(content)
    .flatMap(value => value)
    .filter(item => item?.type === 'revolving_credit')

  const followUpItem = revolvingCreditItems.find(item => item.negotiationType !== 'offer')
  const currentItem = followUpItem ? followUpItem : revolvingCreditItems[0]
  const isOffer = currentItem?.negotiationType === 'offer'
  const statusIsAvailable = currentItem?.negotiationStatus === 'available'
  if (isOffer && statusIsAvailable) {
    return {
      type: 'offer',
      id: currentItem?.id,
      title: 'Ative o Giro Fácil',
      subtitle: `São ${stringFormat.currency(currentItem?.amount)} disponíveis`,
      amount: currentItem?.amount | 0,
      negotiationStatus: currentItem?.negotiationStatus
    }
  } else {
    if (!currentItem || !currentItem.metadata) return null

    return {
      ...currentItem?.metadata,
      id: currentItem?.id,
      type: 'followUp',
      freeLimit: currentItem?.freeLimitAmount,
      contractedAmount: currentItem.contractedAmount
    }
  }
}

type RevolvingCreditActionMainMenuProps = {
  document: string
  organizationId: string
  showValue: boolean
}

function hiddenTextAmount(text: string) {
  const regex = /R\$\s*\d{1,3}(?:\.\d{3})*(?:,\d{2})?/g
  const newText = text.replace(regex, 'R$ •••')

  return newText
}

export function PortsRevolvingCreditActionMainMenu({
  document,
  organizationId,
  showValue
}: RevolvingCreditActionMainMenuProps) {
  const [hasCreditAccess, setHasCreditAccess] = useState<boolean>(false)
  const [revolvingCreditIsOpen, toggleOpenRevolvingCredit] = useToggle(false)
  const { data: dataCreditAccess } = useCheckCreditAccess({ document }, { enabled: hasCreditAccess })
  const { data, isError, refetch } = useRevolvingCredit(
    { document },
    { enabled: Boolean(dataCreditAccess?.content?.hasRevolvingCredit) }
  )
  const metadata = findRevolvingCreditMetadata(data?.content || ({} as MainMenuRevolvingCreditContent))
  const isFollowUp = metadata?.type === 'followUp'
  const isOffer = metadata?.type === 'offer'

  const analiticaOfferType = getRevolvingCreditOfferType({
    amountLimit: metadata?.amount || metadata?.freeLimit || 0,
    type: metadata?.type,
    contractedAmount: metadata?.contractedAmount
  })

  const analiticaProps = useMemo(
    () => ({
      offerId: isOffer ? metadata?.id : null,
      freeLimit: isFollowUp ? metadata?.freeLimit : null,
      offerStatus: isOffer ? metadata?.negotiationStatus : null,
      offerValue: isOffer ? metadata?.amount : null,
      productId: isFollowUp ? metadata?.id : null,
      type: analiticaOfferType
    }),
    [
      isOffer,
      metadata?.id,
      metadata?.freeLimit,
      metadata?.negotiationStatus,
      metadata?.amount,
      isFollowUp,
      analiticaOfferType
    ]
  )

  useEffect(() => {
    ;(async () => {
      const hasAccess = await checkCreditAccess({
        organizationId,
        document
      })

      setHasCreditAccess(hasAccess)
    })()
  }, [organizationId, document])

  useEffect(() => {
    const balanceViewInSessionStorage = CreditBucket.get(SESSION_KEY)
    const shouldDispatchBalanceViewEvent = !balanceViewInSessionStorage

    if (metadata?.id && shouldDispatchBalanceViewEvent) {
      analitica.events.home.homeRevolvingCreditBalanceView({ ...analiticaProps })

      CreditBucket.set(SESSION_KEY, 'true')
    }
  }, [analiticaProps, metadata?.id])

  const onClickInBalanceCard = () => {
    if (metadata?.id) {
      analitica.events.home.homeRevolvingCreditBalanceClick({ ...analiticaProps })
      return isFollowUp ? goToRevolvingCredit() : toggleOpenRevolvingCredit()
    }
  }

  const goToRevolvingCredit = () => {
    if (metadata?.id) {
      return BlackBird.travelTo({
        pathname: `/credito/giro-facil/${metadata?.id}`
      })
    }
  }

  if (isError) {
    return (
      <Flex width="100%" mt={theme.space.medium} alignContent="center" justifyContent="center" flexWrap="wrap">
        <Text size="small" color="neutral" weight="regular">
          Erro ao carregar Giro Fácil
        </Text>
        <Button
          color="positive"
          size="small"
          icon="action-arrow-refresh-outline"
          onClick={() => refetch()}
          variant="contentOnly"
        >
          Atualizar
        </Button>
      </Flex>
    )
  }

  if (metadata) {
    return (
      <>
        <Flex
          mt={theme.space.medium}
          justifyContent="space-between"
          alignItems="center"
          style={{ cursor: 'pointer' }}
          onClick={() => onClickInBalanceCard()}
          data-testid="revolving-credit-action-main-menu"
        >
          <Box>
            <Text size="small" color="neutralHigh" weight="semi">
              {metadata?.title}
            </Text>
            <Text size="small" color="neutral" weight="regular">
              {showValue ? metadata?.subtitle : isFollowUp ? hiddenTextAmount(metadata?.subtitle) : metadata?.subtitle}
            </Text>
          </Box>
          <Icon use="chevron-right-outline" color="neutralLow" style={{ marginLeft: theme.space.small }} />
        </Flex>

        {!isFollowUp && (
          <OnboardingModal
            open={revolvingCreditIsOpen}
            toggle={toggleOpenRevolvingCredit}
            offerId={metadata?.id}
            amount={metadata?.amount || 0}
          />
        )}
      </>
    )
  }

  return null
}
