import { Endpoints } from '../endpoints'
import { httpClient } from '../http-client'

import { type SwhResponse } from './types'

export namespace Categories {
  export type Item = { id: string; name: string }

  export const get = async (id: string): Promise<Item[]> => {
    const response = await httpClient.get<SwhResponse<Item>>(Endpoints.categories(id))
    return response.data.items
  }

  export const subCategories = async (storeId: string, categoryId: string): Promise<Item[]> => {
    const response = await httpClient.get<SwhResponse<Item>>(Endpoints.subCategories(storeId, categoryId))
    return response.data.items
  }
}
