import { type ChangeEvent, useState } from 'react'
import { Button, Drawer, Icon, InputField, Text } from '@stone-payments/jade'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { type Actions } from 'swh/catalog/products-upsert'
import { getPath, plurals, setPath } from 'swh/shared/utils'
import TheMaskInput from 'the-mask-input'

// eslint-disable-next-line @nx/enforce-module-boundaries
import { useErrors } from '../../../../catalog/products-upsert/src/lib/errors-context'
import { PageCard } from '../page-container'

type ProductRowProps = {
  variant: Actions.Variant
  index: number
  gtin: boolean
  hasVariants: boolean
  sku: boolean
}

type K = keyof Actions.Variant | (string & {})

const ProductRow = (props: ProductRowProps) => {
  const variant = props.variant
  const [errors] = useErrors()
  const [open, setOpen] = useState(false)
  const [state, setState] = useState<Actions.Variant>(variant)

  // eslint-disable-next-line @typescript-eslint/ban-types
  const field = (suffix: K) => suffix

  const errorField = (suffix: K) => (errors === null ? null : getPath(errors, `variants[${props.index}].${suffix}`))

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    return setState(prev => setPath(prev, name, value))
  }

  const saleError = errorField('price.sale')

  const stockError = errorField('stock.current')

  const skuError = errorField('sku')

  const gtinError = errorField('tax.gtin')

  const reduceIcmsError = errorField('tax.reduceIcms')

  const reduceIcmsStError = errorField('tax.reduceIcmsSt')

  const cBenefError = errorField('tax.cBenef')

  const legalIcmsError = errorField('tax.legalIcms')

  const cstIpiError = errorField('tax.cstIpi')

  const quoteIpiError = errorField('tax.quoteIpi')

  const codeIpiError = errorField('tax.codeIpi')

  const legalIpiError = errorField('tax.legalIpi')

  const cstPisError = errorField('tax.cstPis')

  const quotePisError = errorField('tax.quotePis')

  const cstCofinsError = errorField('tax.cstCofins')

  const quoteCofinsError = errorField('tax.quoteCofins')

  const ncmError = errorField('tax.ncm')

  const cestError = errorField('tax.cest')

  const csosnError = errorField('tax.csonsn')

  const quoteIcmsError = errorField('tax.quoteIcms')

  const quoteIcmsStError = errorField('tax.quoteIcmsSt')

  const quoteIcmsDifError = errorField('tax.quoteIcmsDif')

  const cfopError = errorField('tax.cfop')

  return (
    <tr className="p-2">
      {props.hasVariants ? <td>{variant.name}</td> : null}
      <td>
        <InputField
          value={state.price.sale.toString()}
          onChange={onChange}
          name={field('price.sale')}
          error={!!saleError}
          supportText={saleError}
          label=""
          mask="money"
          as={TheMaskInput}
          placeholder="00,00"
        />
      </td>
      <td>
        <InputField
          value={state.stock.current.toString()}
          onChange={onChange}
          name={field('stock.current')}
          error={!!stockError}
          supportText={stockError}
          label=""
          mask="int"
          as={TheMaskInput}
          placeholder="00"
        />
      </td>
      <td>
        <span className="flex justify-center items-center">
          <input type="hidden" value={JSON.stringify(state)} name={`variants.items[${props.index}]`} />
          <Button onClick={() => setOpen(true)} variant="primary-ghost" className="flex items-center">
            Adicionar
          </Button>
          <button
            type="button"
            onClick={() => setOpen(true)}
            className="m-0 p-0 appearance-none bg-transparent border-0 mt-2 cursor-pointer"
          >
            <Icon size="small" use="chevron-right" className="pt-2" />
          </button>
          <Drawer backdrop toggle={setOpen} title={variant.name} open={open}>
            <div className="p-jade-250 space-y-jade-200">
              <p>
                Preencha os dados de tributação do produto para que seja possível emitir a nota fiscal de sua venda.
              </p>
              <div className="grid grid-cols-1 gap-jade-200 items-baseline">
                <InputField
                  label="SKU"
                  name={field('sku')}
                  error={!!skuError}
                  supportText={skuError}
                  onChange={onChange}
                  placeholder="STN-001..."
                  required={props.sku}
                  value={state.sku ?? ''}
                />
                <InputField
                  label="Código de barras"
                  name={field('tax.gtin')}
                  error={!!gtinError}
                  supportText={gtinError}
                  onChange={onChange}
                  placeholder="78901..."
                  required={props.gtin}
                  value={state.tax.gtin}
                />
              </div>
              <div className="border-solid border-transparent border-b border-b-jade-border-low w-full block font-medium">
                <p className="uppercase text-jade-content-medium">Tributação da variante</p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-jade-200 items-end">
                <div className="w-full md:col-span-2">
                  <InputField
                    onChange={onChange}
                    value={state.tax.ncm}
                    name={field('tax.ncm')}
                    error={!!ncmError}
                    supportText={ncmError}
                    label="Cód. NCM"
                    placeholder="0"
                  />
                </div>
                <InputField
                  onChange={onChange}
                  value={state.tax.cfop}
                  name={field('tax.cfop')}
                  error={!!cfopError}
                  supportText={cfopError}
                  label="Cód. CFOP"
                  placeholder="0"
                />
                <InputField
                  onChange={onChange}
                  value={state.tax.cest}
                  name={field('tax.cest')}
                  error={!!cestError}
                  supportText={cestError}
                  label="Cód. CEST"
                  placeholder="0"
                />
                <InputField
                  onChange={onChange}
                  value={state.tax.csosn}
                  name={field('tax.csosn')}
                  error={!!csosnError}
                  supportText={csosnError}
                  label="CST ICMS (CSOSN)"
                  placeholder="0"
                />
                <InputField
                  onChange={onChange}
                  leadingItem={{ type: 'text', content: '%' }}
                  value={state.tax.quoteIcms}
                  name={field('tax.quoteIcms')}
                  error={!!quoteIcmsError}
                  supportText={quoteIcmsError}
                  label="Alíq. ICMS"
                  placeholder="0"
                />
                <InputField
                  onChange={onChange}
                  leadingItem={{ type: 'text', content: '%' }}
                  value={state.tax.quoteIcmsSt}
                  name={field('tax.quoteIcmsSt')}
                  error={!!quoteIcmsStError}
                  supportText={quoteIcmsStError}
                  label="Alíq. ICMS ST"
                  placeholder="0"
                />
                <InputField
                  onChange={onChange}
                  leadingItem={{ type: 'text', content: '%' }}
                  value={state.tax.quoteIcmsDif}
                  name={field('tax.quoteIcmsDif')}
                  error={!!quoteIcmsDifError}
                  supportText={quoteIcmsDifError}
                  label="Alíq. Dif ICMS"
                  placeholder="0"
                />
                <InputField
                  onChange={onChange}
                  leadingItem={{ type: 'text', content: '%' }}
                  value={state.tax.reduceIcms}
                  name={field('tax.reduceIcms')}
                  error={!!reduceIcmsError}
                  supportText={reduceIcmsError}
                  label="Redução ICMS"
                  placeholder="00"
                />
                <InputField
                  onChange={onChange}
                  leadingItem={{ type: 'text', content: '%' }}
                  error={!!reduceIcmsStError}
                  supportText={reduceIcmsStError}
                  name={field('tax.reduceIcmsSt')}
                  value={state.tax.reduceIcmsSt}
                  label="Redução ICMS ST"
                  placeholder="00"
                />
                <InputField
                  onChange={onChange}
                  value={state.tax.cBenef}
                  name={field('tax.cBenef')}
                  error={!!cBenefError}
                  supportText={cBenefError}
                  label="Benef. ICMS (CBenef)"
                  placeholder="ES000000"
                />
                <InputField
                  onChange={onChange}
                  label="Amparo legal ICMS"
                  placeholder="00"
                  error={!!legalIcmsError}
                  supportText={legalIcmsError}
                  value={state.tax.legalIcms}
                  name={field('tax.legalIcms')}
                />
                <InputField
                  onChange={onChange}
                  label="CST IPI"
                  placeholder="000"
                  error={!!cstIpiError}
                  supportText={cstIpiError}
                  value={state.tax.cstIpi}
                  name={field('tax.cstIpi')}
                />
                <InputField
                  onChange={onChange}
                  leadingItem={{ type: 'text', content: '%' }}
                  label="Alíquota IPI"
                  placeholder="00"
                  error={!!quoteIpiError}
                  supportText={quoteIpiError}
                  value={state.tax.quoteIpi}
                  name={field('tax.quoteIpi')}
                />
                <InputField
                  onChange={onChange}
                  label="Código Enq. IPI"
                  placeholder="000"
                  error={!!codeIpiError}
                  supportText={codeIpiError}
                  value={state.tax.codeIpi}
                  name={field('tax.codeIpi')}
                />
                <InputField
                  onChange={onChange}
                  label="Amparo legal IPI"
                  placeholder="00"
                  error={!!legalIpiError}
                  supportText={legalIpiError}
                  value={state.tax.legalIpi}
                  name={field('tax.legalIpi')}
                />
                <InputField
                  onChange={onChange}
                  label="CST PIS"
                  placeholder="0"
                  error={!!cstPisError}
                  supportText={cstPisError}
                  value={state.tax.cstPis}
                  name={field('tax.cstPis')}
                />
                <InputField
                  onChange={onChange}
                  leadingItem={{ type: 'text', content: '%' }}
                  label="Alíquota PIS"
                  placeholder="00"
                  error={!!quotePisError}
                  supportText={quotePisError}
                  value={state.tax.quotePis}
                  name={field('tax.quotePis')}
                />
                <InputField
                  onChange={onChange}
                  label="CST COFINS"
                  placeholder="000"
                  error={!!cstCofinsError}
                  supportText={cstCofinsError}
                  value={state.tax.cstCofins}
                  name={field('tax.cstCofins')}
                />
                <InputField
                  onChange={onChange}
                  leadingItem={{ type: 'text', content: '%' }}
                  label="Alíquota COFINS"
                  placeholder="00"
                  error={!!quoteCofinsError}
                  supportText={quoteCofinsError}
                  value={state.tax.quoteCofins}
                  name={field('tax.quoteCofins')}
                />
              </div>
            </div>
          </Drawer>
        </span>
      </td>
    </tr>
  )
}

type Props = {
  gtin: boolean
  hasVariants: boolean
  sku: boolean
  variants: Actions.Variant[]
}

export const SwhProductForm = (props: Props) => {
  return (
    <PageCard>
      {props.hasVariants ? (
        <Text>
          {plurals(props.variants.length, {
            0: 'NENHUMA VARIANTE GERADA',
            1: 'UMA VARIANTE GERADA',
            many: x => `${x} VARIANTES GERADAS`
          })}
        </Text>
      ) : null}
      <table className="table table-auto border-separate border-spacing-jade-100">
        <thead>
          <tr className="text-left">
            {props.hasVariants ? (
              <th key="variants" className="font-normal">
                Variante
              </th>
            ) : null}
            <th key="price" className="font-normal">
              Preço
            </th>
            <th key="stock" className="font-normal">
              Estoque
            </th>
            <th key="tax" className="font-normal text-center">
              Tributação
            </th>
            <th key="none" />
          </tr>
        </thead>
        <tbody>
          {props.variants.map((variant, index) => (
            <ProductRow
              key={variant.name}
              gtin={props.gtin}
              hasVariants={props.hasVariants}
              index={index}
              sku={props.sku}
              variant={variant}
            />
          ))}
        </tbody>
      </table>
    </PageCard>
  )
}
