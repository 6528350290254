import { type ChangeEvent, type MouseEvent, useMemo, useState } from 'react'
import {
  type DropdownSelectionOption,
  type Option,
  Button,
  DropdownSelection,
  InputField,
  Select,
  Text
} from '@stone-payments/jade'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { type Actions } from 'swh/catalog/products-upsert'
import { PageCard, useStoreId } from 'swh/shared/components'
import { type ProductCatalog, Variants } from 'swh/shared/http'
import { Dict, permutation, plurals } from 'swh/shared/utils'

import { uuid } from '~/domains/platform/lib/crypto'

const DEFAULT_VARIANT_OPTIONS_SELECTED = 2

const MAX_SIZE_VARIANT_OPTION = 15

export type CreateVariationOption = {
  id: string
  KEY: string
  name: string
  variantTypeId: string
}

export type VariationType = { id: string; name: string }

type CreateVariantType = VariationType & {
  variationOptions: Dict<string, CreateVariationOption>
}

export enum UpsertMode {
  new = 'new',
  edit = 'edit'
}

export type CustomVariationOption = Variants.VariationOption & {
  parentId: string
}

type VariantsFormProps = {
  mode: UpsertMode
  product: ProductCatalog.GetProductById
  builtinVariants: Variants.VariationType[]
  variants: Actions.Variant[]
  onCreateVariants: (
    variants: VariationType[],
    combinations: Array<{ combinations: CustomVariationOption[]; name: string }>
  ) => void
}

type State = { items: Dict<string, CreateVariantType> }

const combineVariations = (items: CreateVariantType[]) =>
  items.length === 0
    ? []
    : permutation(
        ...items.reduce((acc, x) => {
          if (x.name === '') return acc
          return Array.from(x.variationOptions.values()).reduce<CreateVariationOption[]>(
            (acc, el) => (el.name === '' ? acc : [...acc, el]),
            []
          )
        }, [])
      )

const toVariantCombination = (id: string, x: Variants.VariationOption, KEY?: string): CreateVariationOption => ({
  KEY: KEY ?? x.id,
  id: x.id,
  name: x.name,
  variantTypeId: id
})

const fetchCreateModeVariants = (props: VariantsFormProps): State => {
  const first = props.builtinVariants[0]
  const items = new Dict<string, CreateVariantType>()
  if (first) {
    const opts: CreateVariationOption[] = [
      ...first.variationOptions
        .slice(0, DEFAULT_VARIANT_OPTIONS_SELECTED)
        .map(opt => toVariantCombination(first.id, opt, uuid())),
      { id: '', KEY: uuid(), variantTypeId: first.id, name: '' }
    ]
    const variantType: CreateVariantType = {
      id: first.id,
      name: first.name,
      variationOptions: new Dict<string, CreateVariationOption>(opts.map(x => [x.KEY, x]))
    }
    items.set(first.id, variantType)
  }
  return { items }
}

const fetchEditModeVariants = (props: VariantsFormProps): State => {
  const items = new Dict<string, CreateVariantType>()
  if (!props.product) return { items }
  props.product.variants.forEach(variant => {
    variant.chosenVariationOptions.forEach(choose => {
      const x = items.get(choose.id)
      if (x) {
        const clone = x.variationOptions.clone()
        clone.set(choose.option.id, {
          KEY: uuid(),
          variantTypeId: x.id,
          id: choose.option.id,
          name: choose.option.name
        })
        return items.set(choose.id, { ...x, variationOptions: clone })
      }
      items.set(choose.id, {
        id: choose.id,
        name: choose.name,
        variationOptions: new Dict<string, CreateVariationOption>([
          [choose.option.id, { KEY: uuid(), variantTypeId: choose.id, id: choose.option.id, name: choose.option.name }]
        ])
      })
    })
  })
  items.forEach(x => {
    const id = uuid()
    x.variationOptions.set(id, { KEY: id, variantTypeId: x.id, id: '', name: '' })
  })
  return { items }
}

export const VariantsForm = (props: VariantsFormProps) => {
  const storeId = useStoreId()
  const [state, setState] = useState<State>(() =>
    props.mode === UpsertMode.new ? fetchCreateModeVariants(props) : fetchEditModeVariants(props)
  )
  const combinations = combineVariations(Array.from(state.items.values()))

  const builtinVariants = useMemo(
    () =>
      props.builtinVariants.reduce<Record<string, Variants.VariationType>>(
        (acc, el) => ({
          ...acc,
          [el.id]: el
        }),
        {}
      ),
    [props.builtinVariants]
  )

  const variantOptions: Option[] = props.builtinVariants.map(x => ({
    value: x.id,
    label: x.name,
    hidden: state.items.has(x.id)
  }))

  const onChangeVariantType = (e: ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value
    const toRemove = e.target.dataset.id!
    const selected = builtinVariants[id]
    setState(prev => {
      const clone = prev.items.clone()
      clone.delete(toRemove)
      const options = new Dict<string, CreateVariationOption>()
      const variation = builtinVariants[id]
      variation.variationOptions.slice(0, DEFAULT_VARIANT_OPTIONS_SELECTED).forEach(opt => {
        const KEY = uuid()
        options.set(KEY, { id: opt.id, name: opt.name, variantTypeId: opt.id, KEY: KEY })
      })
      const optionRef = uuid()
      options.set(optionRef, { id: '', name: '', variantTypeId: '', KEY: optionRef })
      return { ...prev, items: clone.set(id, { id, variationOptions: options, name: selected.name }) }
    })
  }

  const onDeleteVariant = (e: MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.dataset.id!
    const parentId = e.currentTarget.dataset.parent!
    setState(prev => {
      const clone = prev.items.clone()
      const parent = clone.get(parentId)!
      const variations = parent.variationOptions.clone()
      variations.delete(id)
      clone.set(parentId, { ...parent, variationOptions: variations })
      return { ...prev, items: clone }
    })
  }

  const onChangeVariationLabel = (args: { text: string; id: string; KEY: string; variationTypeId: string }) =>
    setState((prev): State => {
      const items = prev.items.clone()
      const variationType = items.get(args.variationTypeId)!
      const options = variationType.variationOptions.clone()
      if (!options.has(args.KEY)) return prev
      const variationOption = options.get(args.KEY)!
      const hasEmpty = Array.from(options.values()).some(x => x.id === '')
      if (!hasEmpty) {
        if (options.size <= MAX_SIZE_VARIANT_OPTION) {
          const KEY = uuid()
          options.set(KEY, { id: '', KEY, name: '', variantTypeId: args.variationTypeId })
        }
      }
      options.set(args.KEY, { ...variationOption, name: args.text, id: args.id })
      items.set(args.variationTypeId, { ...variationType, variationOptions: options })
      return { items }
    })

  const addVariationType = () =>
    setState((prev): State => {
      const clone = prev.items.clone()
      const variantId = uuid()
      const variationOptionKey = uuid()
      clone.set(variantId, {
        id: variantId,
        name: '',
        variationOptions: new Dict([
          [variationOptionKey, { id: '', name: '', variantTypeId: variantId, KEY: variationOptionKey }]
        ])
      })
      return { items: clone }
    })

  const createVariants = async () => {
    if (state.items.size === 0) return
    const items = Array.from(state.items.values())
    const requests = items
      .filter(x => Array.from(x.variationOptions.values()).filter(x => x.id !== ''))
      .map(x => ({ ...x, variationOptions: Array.from(x.variationOptions.values()) }))
    const result = await Promise.all(
      requests.map(async item =>
        Variants.post(
          storeId,
          {
            id: item.id,
            name: item.name,
            variationOptions: item.variationOptions.map(x => ({ id: x.id, name: x.name }))
          },
          builtinVariants[item.id].variationOptions
        )
      )
    )
    // eslint-disable-next-line unused-imports/no-unused-vars
    const variationTypes = state.items.map(({ variationOptions: _, ...item }): VariationType => item)
    const combinations = permutation(
      ...result.map(x =>
        x.variationOptions.map(
          (v): CustomVariationOption => ({
            ...v,
            parentId: x.id
          })
        )
      )
    )
    const combine = combinations.map(x => ({
      combinations: x,
      name: x.map(l => l.name).join('/')
    }))
    props.onCreateVariants(variationTypes, combine)
  }

  return (
    <PageCard>
      <Text variant="overline" className="uppercase text-jade-50 font-semibold">
        Variantes do produto
      </Text>
      <p>Escolha e adicione até 3 tipos de variantes ao seu produto</p>
      {state.items.map((variationType, variationTypeIndex) => (
        <div
          key={variationType.id}
          className="flex flex-row flex-nowrap gap-4 w-full border-0 pb-jade-200 border-b border-solid border-jade-border-low"
        >
          <Select
            className="min-w-52"
            data-id={variationType.id}
            label="Tipo da variante"
            name={`items[${variationTypeIndex}].value`}
            onChange={onChangeVariantType}
            options={variantOptions}
            value={variationType.id}
          />
          <ul className="w-full gap-jade-200 flex flex-col">
            {variationType.variationOptions.map((variationOption, variationOptionIndex, currentMap) => {
              const isFirst = variationOptionIndex === 0
              const current = builtinVariants[variationType.id]
              const options = current?.variationOptions ?? []

              const dropdownOptions = options.reduce<DropdownSelectionOption[]>((acc, x) => {
                const variantOptions = state.items.get(variationType.id)!
                const values = Array.from(variantOptions.variationOptions.values())
                const hasOption = values.some(s => s.id === x.id)
                return hasOption ? acc : [...acc, { value: x.id, label: x.name, selected: x.id === variationOption.id }]
              }, [])

              const onChangeInputField = (e: ChangeEvent<HTMLInputElement>) => {
                onChangeVariationLabel({
                  text: e.target.value,
                  KEY: variationOption.KEY,
                  id: uuid(),
                  variationTypeId: variationType.id
                })
              }

              const onOptionChange = (selected: Omit<DropdownSelectionOption, 'selected'>[]) => {
                const first = selected[0]!
                if (!first) return
                onChangeVariationLabel({
                  text: first.label,
                  KEY: variationOption.KEY,
                  id: first.value,
                  variationTypeId: variationType.id
                })
              }

              return (
                <li key={variationOption.KEY} className="w-full flex flex-row gap-2 items-end">
                  <DropdownSelection
                    type="single"
                    key={`dropdown-selection-${variationOption.KEY}`}
                    options={dropdownOptions}
                    title="Opções da variante"
                    defaultValue={variationOption.id}
                    onOptionChange={onOptionChange}
                    trigger={
                      <InputField
                        className="w-full"
                        data-id={variationOption.id}
                        value={variationOption.name}
                        onChange={onChangeInputField}
                        data-parent={variationType.id}
                        onKeyDown={e => e.stopPropagation()}
                        placeholder="Adicionar outra opção"
                        label={isFirst ? 'Opções da variante' : ''}
                        data-last={variationOptionIndex === currentMap.length - 1}
                      />
                    }
                  />
                  <div className={isFirst ? 'mt-4' : ''}>
                    <Button
                      icon="trash"
                      variant="neutral-ghost"
                      onClick={onDeleteVariant}
                      data-id={variationOption.KEY}
                      data-parent={variationType.id}
                      disabled={variationOption.name === ''}
                    />
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      ))}
      <div className="flex justify-end gap-jade-100">
        <Button disabled={state.items.size >= 3} size="small" onClick={addVariationType} variant="neutral-ghost">
          Adicionar outro tipo
        </Button>
        <Button size="small" onClick={createVariants}>
          Criar (
          {plurals(combinations.length, {
            0: 'grade',
            1: 'grade',
            many: c => `${c} grades`
          })}
          )
        </Button>
      </div>
    </PageCard>
  )
}
