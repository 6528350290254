import { type Dispatch, type SetStateAction, createContext, useContext, useState } from 'react'
import { type Stores } from 'swh/shared/http'

type State = {
  store: Stores.Item
}

const SwhConfigContext = createContext<[State, Dispatch<SetStateAction<State>>]>(null)

export const SwhConfigContextProvider = ({ children }) => {
  const value = useState<State>(null)
  return <SwhConfigContext.Provider value={value}>{children}</SwhConfigContext.Provider>
}

export const useSwhConfigContext = () => useContext(SwhConfigContext)

export const useSwhConfig = () => useContext(SwhConfigContext)[0]
