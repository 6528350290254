import { type MouseEvent, useEffect, useState } from 'react'
import { IconButton, Text } from '@stone-payments/jade'
import prettyBytes from 'pretty-bytes'

import { type DndUploadProps, type Message } from './types'

type DefaultViewerProps = {
  message?: Message
} & Partial<DndUploadProps>

const FileViewer = ({
  file,
  message,
  icon,
  loading,
  onDeleteFile,
  onUpdateFile,
  allowDelete,
  allowUpdate
}: DefaultViewerProps & { file: File }) => {
  const [info, setInfo] = useState({
    url: '',
    size: ''
  })

  useEffect(() => {
    if (!file.type.includes('image')) return

    const url = URL.createObjectURL(file)
    setInfo({ url, size: prettyBytes(file.size) })
    URL.revokeObjectURL(url)

    return () => URL.revokeObjectURL(url)
  }, [file])

  const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onDeleteFile?.(file)
  }

  const handleUpdate = () => {
    onUpdateFile?.(file)
  }

  return (
    <div className="flex flex-row gap-jade-200 justify-between w-full">
      <header className="flex flex-row gap-jade-200 items-center">
        {info.url && (
          <img src={info.url} className="size-jade-500 rounded-jade-xsmall" alt={`Miniatura do arquivo ${file.name}`} />
        )}

        {icon}

        <div className="flex flex-col gap-1">
          <Text color="medium" variant="text-large" weight="semibold" as="span">
            {file.name}
          </Text>

          {info.size && (
            <Text color="medium" weight="regular" as="span">
              {info.size}
            </Text>
          )}

          {message && (
            <Text color={message.color} weight="regular" as="span">
              {message.text}
            </Text>
          )}
        </div>
      </header>

      {allowDelete && <IconButton icon="trash" size="large" disabled={loading} onClick={handleDelete} />}

      {allowUpdate && !allowDelete && (
        <IconButton icon="arrow-curve-cycle" size="large" disabled={loading} onClick={handleUpdate} />
      )}
    </div>
  )
}

export const DefaultViewer = ({
  files,
  allowDelete,
  allowUpdate,
  onDeleteFile,
  onUpdateFile,
  icon,
  message,
  loading
}: DefaultViewerProps) => {
  if (!files?.length) return null

  return (
    <div className="flex flex-col items-center justify-center rounded-jade-small p-jade-200 border-jade-border-medium bg-jade-background-body mt-jade-100">
      <ul className="w-full space-y-jade-200">
        {files.map(file => {
          return (
            <FileViewer
              allowDelete={allowDelete}
              allowUpdate={allowUpdate}
              file={file}
              onDeleteFile={onDeleteFile}
              onUpdateFile={onUpdateFile}
              icon={icon}
              message={message}
              loading={loading}
              key={file.name}
            />
          )
        })}
      </ul>
    </div>
  )
}
